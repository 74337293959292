<template>
  <div class="courseVideoBg">
    <div>
      <p class="courseVideoName">{{videoDetails.name}}</p>
      <div style="margin-left: 6px" class="courseVideoFlex">
        <div :style="{width: courseVideoWd}" >
          <div style="display: flex;align-items: center">
            <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions"  :style="{height:videoHeight}"/>
            <img v-if="marketCourse&&courseSection" style="width: 18px;cursor: pointer" src="images/conceal.png" @click="goCourseSection()">
            <img v-if="marketCourse&&!courseSection" style="width: 18px;cursor: pointer" src="images/reveal.png" @click="goCourseSection()">
          </div>
        </div>
        <div class="courseVideoChapter" :style="{height:videoHeight}" v-if="courseSection">
          <el-card>
            <div class="courseVideoCatalogue">
              <p v-if="!marketCourse">视频介绍</p>
              <p v-if="marketCourse"  v-for="(item,index) in videoTab" :key="index" @click="changeTab(index)" :class="tabShow==index?'videoTabActive':''">{{item.name}}</p>
            </div>
          </el-card>
          <div >
<!--            章节-->
            <div  v-if="tabShow==0&&marketCourse">
              <el-menu
                  :default-active="taskActiveId"
                  class="el-menu-vertical-demo">
                <el-submenu v-for="(item, index) in courseDetailArr"
                            :key="index"
                            :index="item.id?item.id:''">
                  <template slot="title">
                    <span >{{ item.name }}</span>
                  </template>
                  <el-menu-item-group v-for="(itemChildren, indexChildren) in item.courseItemResources"
                                      :key="indexChildren">
                    <el-menu-item :index="itemChildren.id+''" @click="goVideoDetail(itemChildren)">
<!--                      <i class="el-icon-video-play"></i>-->
                      <span class="sourceWd">{{ itemChildren.sourceRawName }}</span>
                      <template>
                        <img style="width: 20px" v-if="itemChildren.freeData==0&&newUserInfo&&!newUserInfo.teacherType&&isShowBuyBtn" src="images/lock.png" alt="" class="lock"/>
                        <img style="width: 20px" v-if="itemChildren.freeData==0&&newUserInfo&&newUserInfo.teacherType" src="images/lock.png" alt="" class="lock"/>
                        <img style="width: 20px" v-if="itemChildren.freeData==0&&!newUserInfo" src="images/lock.png" alt="" class="lock"/>
                      </template>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </div>
<!--            信息-->
            <div class="courseInfo" v-if="tabShow==1||!marketCourse">
              <p class="courseContentColor" v-html="videoDetails.profiles"></p>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 6px">
        <p class="recommendFont">相关推荐</p>
        <div class="correlationRecommend">
          <div v-for="(item,index) in VideoList" :key="index" class="recommendVideo" @click="goCourserDetail(item)">
            <img :src="item.logo?item.logo:'images/zanwuImgs.png'" class="recommendImg"/>
            <p>{{item.name}}</p>
          </div>
        </div>
      </div>
    </div>
<!--    <el-dialog-->
<!--        :visible.sync="videoWhindow"-->
<!--        :show-close="true"-->
<!--        :append-to-body="true"-->
<!--        :destroy-on-close="true">-->
<!--      <div class="resourceImgas" v-if="videoWhindow">-->
<!--        <vue-aliplayer-v2 ref="VueAliplayerV2" :options="videoOptions" />-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>
<script>
import {
  aliYunFileVideoPlayAuth,
  preparingVideoList,
  resourceListByItem,
  getCorrelationCourse,
  checkUserPayCourse, courseList
} from "@/api";
  import VueAliplayerV2 from 'vue-aliplayer-v2';
  export default {
    components:{
      VueAliplayerV2
    },
    data(){
      return{
        courseCatalogueShow:1,
        videoDetails:{},
        videoOptions: {},
        videoWhindow:false,
        videoPage:1,
        videoSize:5,
        VideoList:[],
        videoTab:[
          {id:1,name:'目录'},
          // {id:2,name:'信息'},
        ],
        tabShow:0,
        marketCourse:'',
        courseDetailArr:[],
        taskActiveId:'',
        isShowBuyBtn:false,
        courseVideoDetails:{},
        lockIcon:false,
        newUserInfo:{},
        videoHeight:0,
        courseVideoWd:'80%',
        courseSection:true
      }
    },
    methods:{
      //显示隐藏章节目录
      goCourseSection(){
        this.courseSection=!this.courseSection
        this.courseVideoWd='100%'
      },
      getVideo() {
        let videoDetails=JSON.parse(sessionStorage.getItem('videoDetails'))
        let data={
          videoId:videoDetails.thirdPartyStorageId
        }
        aliYunFileVideoPlayAuth(data).then((res) => {
          let newPlayAuth = {
            playauth:res.data.playAuth,
            vid:res.data.videoMeta.videoId,
            autoplay:true,
          }
          this.videoOptions = newPlayAuth;
        })
      },
      //  查询用户是够购买课程
      getUserBuyCourse(){
        let data={
          courseId:this.courseVideoDetails.id
        };
        checkUserPayCourse(data).then(res=>{
          if(res.code==0){
            this.isShowBuyBtn=false
          }else{
            this.isShowBuyBtn=true
          }
        })
      },
      //去推荐课程详情
      goCourserDetail(item){
        sessionStorage.setItem('courseVideoDetails',JSON.stringify(item) )
        let routeUrl = this.$router.resolve({
          path: "/courseVideoDetais",
        });
        window.open(routeUrl.href);
      },
      //  获取备考课程列表
      getPreparingVideoList(){
        let data={
          page:this.videoPage,
          size:this.videoSize,
          id:this.videoDetails.id
        };
        preparingVideoList(data).then(res=>{
          this.VideoList=res.data.records;
          this.videoTotal=parseInt(res.data.total)
        })
      },
      //  去备考视频详情
      goVideoDetail(item){
        if(this.newUserInfo&&!this.newUserInfo.teacherType&&item.freeData==0&&this.isShowBuyBtn==true){
          // this.$alert('暂无购买,无法观看哦', '提示', {
          //   confirmButtonText: '确定',
          //   callback: action => {
          //     this.$message({
          //       type: 'info',
          //     });
          //     this.$router.push('./courseVideoDetais')
          //   }
          // });
          this.$confirm('暂无购买,无法观看,点击确定前往购买', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            // this.$router.push('./courseVideoDetais')
            this.$router.replace({path:'./courseVideoDetais'});
          })
          return
        };
        if(this.newUserInfo&&this.newUserInfo.teacherType&&item.freeData==0){
          this.$confirm('暂无购买,无法观看,点击确定前往购买', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.$router.replace({path:'./courseVideoDetais'});
          })
          return
        };
        if(!this.newUserInfo&&item.freeData==0){
          this.$confirm('暂无购买,无法观看,点击确定前往购买', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.$router.replace({path:'./courseVideoDetais'});
          })
          return
        }
        var p = new Promise((resolve, reject) => {
          sessionStorage.setItem('videoDetails',JSON.stringify(item))
          this.$router.push({path: '/courseVideo', query: {videoId:item.id,free:'1',id:this.courseVideoDetails.id,}});
        });
        p.then((value) => {

        })
        this.getVideo()
      },
    //  tab切换
      changeTab(index){
        this.tabShow=index
      },
      //获取章和视频列表
      getResourceListByItem(){
        let data={
          platformCourseId:this.$route.query.id,
          sourceDiff: 'MicroLecture'
        }
        resourceListByItem(data).then(res=>{
          this.courseDetailArr=res.data;

        })
      },
      //获取相关课程列表
      getCorrelationCourseList(){
        let data={
          courseClassifyId:this.courseVideoDetails.courseClassifyId,
          id:this.courseVideoDetails.id
        };
        getCorrelationCourse(data).then(res=>{
          this.VideoList=res.data
        })
      },
      //获取课程列表
      getCourseList() {
        let data={
          page:1,
          size:50,
          schoolId:this.newUserInfo.schoolId
        }
        courseList(data).then(res => {
          if(res.code==0&&res.data.records){
            res.data.records.forEach(item=>{
              if(item.id==this.courseVideoDetails.id){
                this.lockIcon=true
              }
            })
          }
        })
      },
    },
    created() {
      this.getVideo()
      this.videoHeight=(document.documentElement.clientHeight-85)+'px'
    },
    mounted(){
      this.getUserBuyCourse()
      this.taskActiveId=this.$route.query.videoId+''
      this.$nextTick(()=>{
        this.taskActiveId = String(this.$route.query.videoId);
      })
      this.getCourseList()
      this.videoDetails=JSON.parse(sessionStorage.getItem('videoDetails'));
      this.courseVideoDetails=JSON.parse(sessionStorage.getItem('courseVideoDetails'));
      this.newUserInfo=JSON.parse(localStorage.getItem("userInfo")) ;
      this.getPreparingVideoList();
      this.marketCourse=this.$route.query.free;
      this.getResourceListByItem();
      if(this.$route.query.id){
        this.getCorrelationCourseList()
      }

    },
  }
</script>
<style>
  .courseVideoBg{
    padding-top: 65px;
    background: #f7f7f7;
  }
  .sourceWd{
    display: inline-block;
    width:222px;
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
    text-overflow: ellipsis;
  }
  /*.courseVideoBg .prism-player{*/
  /*  height: 600px!important;*/
  /*}*/
  .courseVideoFlex{
    display: flex;
    /*justify-content: space-between;*/
  }
  /*.courseVideoBg>div{*/
  /*  width: 1200px;*/
  /*  margin: 0 auto;*/
  /*}*/
  .courseVideoWidth{
    width: 80%;
    min-width: 500px;
  }
  .courseVideoChapter{
    width: 20%;
    background: white;
    /*height: 600px;*/
    overflow-y: auto;

  }
  .courseVideoBg .courseVideoChapter::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 7px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .courseVideoBg .courseVideoChapter::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    /* box-shadow: inset 0 0 5px rgba( 0, 0, 0, .1); */
    background: #92c1f4;
  }

  .courseVideoBg .courseVideoChapter::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* box-shadow: inset 0 0 1px rgba( 0, 0, 0, .1); */
    border-radius: 10px;
    background: rgba(63, 147, 237, 0.1);
  }

  .schoolTitleHight::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 7px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .schoolTitleHight::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    /* box-shadow: inset 0 0 5px rgba( 0, 0, 0, .1); */
    background: #92c1f4;
  }

  .schoolTitleHight::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* box-shadow: inset 0 0 1px rgba( 0, 0, 0, .1); */
    border-radius: 10px;
    background: rgba(63, 147, 237, 0.1);
  }
  .courseVideoName{
    font-size: 22px;
    margin: 20px 0;
  }
  .courseVideoCatalogue{
    display: flex;
    justify-content: center;
  }
  .courseVideoCatalogue>p{
    cursor: pointer;
    width: 100px;
    text-align: center;
    padding-bottom: 12px
  }
  .courseCatalogueActive{
    color: #4AB152;
    border-bottom: 2px solid #49B151;
  }
  .courseInfo{
    padding: 24px;
  }
  .courseInfo>p{
    margin-bottom: 12px;
  }
  .courseContentColor{
    color: #666666;
  }
  .recommendVideo{
    width: 227px;
    height: 181px;
    background: white;
    border-radius: 4px;
    margin-right: 14px;
    cursor: pointer;
    overflow: hidden;
  }
  .recommendVideo img{
    width: 227px;
    height: 138px;
    border-radius: 4px 4px 0 0;
    transition: all 0.5s;
    object-fit: cover;
  }
  .recommendVideo>p{
    padding: 6px 0 0 12px;
    width: 206px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
  .correlationRecommend{
    display: flex;
    padding-bottom: 60px;
  }
  .recommendFont{
    font-size: 14px;
    color: #666666;
    margin: 20px 0;
  }
  .recommendVideo:hover .recommendImg{
    transform: scale(1.12);
  }
  .videoTabActive{
    color: #409EFF;
    border-bottom: 1px solid #409EFF;
  }
</style>
